<script lang="ts" setup>
import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel'
import { useActiveElement } from '@vueuse/core'
import { Loader2 } from 'lucide-vue-next'
const userStore = useUserStore()
const headers = useRequestHeaders(["cookie"]);
const loading = ref(false)
const stripeLoading = ref(false)
const currentSlide = ref(0)
const activeElement = useActiveElement();
const uiStore = useUiStore()
const { setSelectedPlan, setCheckingOut, triggerPrev, triggerNext, setselectedInterval, toggleDialog, setStripeClientSecret } = uiStore
const { selectedPlan, checkingOut, prev, next, selectedInterval, plans, stripeClientSecret } = storeToRefs(uiStore)
const { selectedTeam } = storeToRefs(userStore)


const pro = computed(() => plans.value.find((plan: any) => plan.name === 'Pro'))
const teams = computed(() => plans.value.find((plan: any) => plan.name === 'Teams'))

console.log(selectedPlan.value)
console.log(teams.value)
console.log(pro.value)

const props = withDefaults(defineProps<{
  team?: boolean;
  pro?: boolean;
}>(), {
  team: false,
  pro: false
});

watch(activeElement, (el, prevEl) => {
  if (el.id !== 'invited-users') {
    el.blur();
  }
});

watch(prev, (val, oldVal) => {
  if (oldVal !== val) {
    scrollPrev();
    currentSlide.value -= 1
  }
})

watch(next, (val, oldVal) => {
  if (oldVal !== val) {
    scrollNext();
    currentSlide.value += 1
  }
})

watch(selectedPlan, () => {
  setStripeClientSecret('')
})

onMounted(async () => {
  if (route.query.subscribe) {
    currentSlide.value = 1
    scrollNext()
  }
});

onBeforeUnmount(() => {
  
  // setSelectedPlan(null)
})

const carouselContainerRef = ref<InstanceType<typeof Carousel> | null>(null)
const carouselContainer2Ref = ref<InstanceType<typeof Carousel> | null>(null)

const api = ref<CarouselApi>()
const api2 = ref<CarouselApi>()

const setApi = (val: CarouselApi) => {
  api.value = val
}

const setApi2 = (val: CarouselApi) => {
  api2.value = val
}


const stop = watch(api, (api) => {
  if (!api)
  return

  // Watch only once or use watchOnce() in @vueuse/core
  nextTick(() => stop())

  api.on('settle', (e, p) => {

    // currentSlide.value = Math.max(0, Math.round(e.scrollProgress()))

  })
})

watch(selectedPlan, () => {
    nextTick(() => {
      scrollNext()
    })
})


const emblaOpts = ref({
  active: true,
  loop: false,
  duration: 15,
  align: 'start'
})

const scrollNext = () => {
  carouselContainerRef.value?.carouselApi.scrollNext()
  carouselContainer2Ref.value?.carouselApi.scrollNext()
  currentSlide.value+=1
}

const scrollPrev = () => {
  
  setCheckingOut(false)
  carouselContainerRef.value?.carouselApi.scrollPrev()
  carouselContainer2Ref.value?.carouselApi.scrollPrev()
  currentSlide.value-=1
}

const route = useRoute()
const redirectPath = route.path

const clientSecret = ref('')
const stripeLoaded = ref(false)


const subscribe = async () => {
  stripeLoading.value = true
  // setTimeout(() => {
  //   setCheckingOut(true)
  // }, 500)
  // console.log(selectedTeam.value)
  // const is_group = selectedTeam.value.metadata?.type =='group'
  console.log('SUBSCRIBING!------------------------')
  const data = await $fetch('/api/subscribe', {
    method: 'POST',
    headers,
    body: {
      price_id: selectedPlan.value.prices[selectedInterval.value].id,
      redirectPath,
      modalOpen: false,
      is_group: selectedPlan.value.name == 'Teams'
    }
  })
  stripeLoading.value = false
  if (data?.message && !stripeClientSecret.value) {
    setStripeClientSecret(data?.message)
  }
  return
}

</script>

<template>
  <div class="w-full h-fit transition-height duration-300 ">
    <!-- <Button class="pointer-events-auto" @click="async ()=>{await toggleDialog(false); console.log('clsing')}">Close</Button> -->
    <div
      :class="[`w-full h-fit md:h-fit md:w-[90vw] max-h-[95vh] grid-rows-2 grid-cols-1 md:grid-rows-1 grid md:grid-cols-2 grid-flow-col auto-cols-auto auto-rows-auto rounded-lg items-center p-0 sm:p-8 flex`, { 'overflow-y-auto': stripeClientSecret ? true : false }]">


      <Carousel v-if="!checkingOut && teams" @init-api="setApi" ref="carouselContainerRef" :opts="emblaOpts"
        :class="['w-full px-6 pointer-events-none order-2 md:order-1']">
        <CarouselContent class="items-end md:items-start">
          <CarouselItem class="flex justify-center ">
            <div>
              <Badge
                class="bg-muted text-primary outline-muted-foreground/40 outline outline-[1px] text-xs font-normal max-w-[90%] ml-4 absolute top-2 md:top-5 z-10">
                <div class="truncate">

                  <p class="hidden sm:inline truncate">
                    Landscape architects, consultants
                    & nurseries
                  </p>
                  <p class="inline-block sm:hidden truncate">
                    LA, consultant
                    & nursery
                  </p>
                </div>
              </Badge>
            </div>
            <PlanCard v-if="props.team" :plan="teams"
              :features="[{ text: 'Species compliance in one click ', icon: 'mingcute:safety-certificate-fill' }, { text: 'Trusted grower-based palettes', icon: 'streamline:nature-ecology-green-house-glass-building-plants-crops-produce-farm' }, { text: 'Stock availability and forecasting', icon: 'material-symbols:auto-graph-rounded' }, { text: 'Consultant review', icon: 'fluent:person-feedback-28-regular' }]" />
          </CarouselItem>
          <CarouselItem key="page3" class="">
            <div class="max-w-[400px]" v-if="selectedPlan">
              <small class="text-sm font-medium leading-none">For a team of one person:</small>

              <RadioGroup v-if="plans && selectedPlan" v-model="selectedInterval" default-value="year"
                class="space-y-6 mt-4">
                <div class="flex items-center space-x-6 w-fit pointer-events-auto cursor-pointer"
                  @dragstart.stop.prevent>
                  <RadioGroupItem id="r1" value="year" />
                  <Label for="r1">
                    <p class="mb-1 text-xl font-semibold tracking-tight">
                      Yearly<Badge class="bg-destructive relative -top-0.5 text-background text-xs font-semibold mx-2 ">
                        Save $240</Badge>
                    </p>
                    <small class="text-sm font-medium text-muted-foreground leading-none">
                      <span>
                        AUD ${{ selectedPlan.prices.year.unit_amount / 100 }}
                      </span>
                      <span class="text-muted-foreground pl-1 font-light">
                        (AUD ${{ selectedPlan.prices.year.unit_amount / 100 / 12 }} / month)
                      </span>
                    </small>
                  </Label>
                </div>
                <div class="flex items-center w-fit space-x-6 pointer-events-auto cursor-pointer"
                  @dragstart.stop.prevent>
                  <RadioGroupItem id="r2" value="month" />
                  <Label for="r2">
                    <p class="mb-1 text-xl font-semibold tracking-tight">
                      Monthly
                    </p>
                    <small class="text-sm font-medium text-muted-foreground leading-none">
                      AUD ${{ selectedPlan.prices.month.unit_amount / 100 }}
                    </small>
                  </Label>
                </div>

              </RadioGroup>
              <div class="text-sm tracking-tight text-primary pt-12">
                <div class="w-full flex justify-between pb-2">
                  <div>
                    Due {{ new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { month: 'long',
                    day: 'numeric', year: 'numeric' }) }}
                  </div>
                  <div>
                    {{ selectedInterval === 'year' ? 'AUD $' +
                    (selectedPlan.prices[selectedInterval].unit_amount/100).toFixed(2) :
                    'AUD $' + (selectedPlan.prices[selectedInterval].unit_amount/100).toFixed(2) }}
                  </div>
                </div>
                <div class="w-full flex justify-between font-semibold">
                  <div>
                    Due today (30 days free)
                  </div>
                  <div>
                    AUD $0
                  </div>
                </div>
              </div>
              <Button @click="subscribe(); setCheckingOut(true)" class="w-full pointer-events-auto mt-8 text-lg">
                Next
              </Button>
            </div>
          </CarouselItem>



        </CarouselContent>
      </Carousel>

      <Carousel v-if="!checkingOut" class=" w-full px-6 sm:px-2 pointer-events-none order-1 md:order-2 md:px-4"
        @init-api="setApi2" :opts="emblaOpts" ref="carouselContainer2Ref">
        <!-- <Badge v-if="props.pro && currentSlide == 0"
        class="bg-background text-muted-foreground outline opacity-75 text-xs font-semibold w-fit left-8 absolute -top-2 z-10">Home and Education</Badge> -->
        <CarouselContent class="items-center">
          <CarouselItem key="page2" class=" flex justify-center md:justify-start">
            <PlanCard v-if="props.pro" :plan="pro"
              :features="[{ text: 'AI powered planting', icon: 'tabler:trees' }, { text: 'Generate quotes', icon: 'ph:invoice' }, { text: 'Choose by cost and maintenance', icon: 'bi:sliders' }]" />
          </CarouselItem>
          <CarouselItem key="page3" class="md:px-6 lg:px-14 ">
            <PlanTimeline class="" :stripeLoaded="true" />
          </CarouselItem>

        </CarouselContent>



      </Carousel>
      <!-- <div class="h-full flex relative flex-col -order-1 md:order-1 w-full md:flex-row md:space-x-4 lg:justify-between m-0 p-0
      bg-center bg-cover bg-no-repeat bg-[url('/euc1.jpg')] h-full rounded-lg rounded-b-none md:rounded-l-none
      md:rounded-r-lg"></div> -->

    </div>

    <div v-if="checkingOut" class="w-full min-h-[50vh] h-fit flex justify-center align-center p-[1.85rem] -mt-[4rem]">
      <PlanConfirm :loading="stripeLoading" :plan="selectedPlan" @loaded="stripeLoaded = true" />

    </div>
    <div v-else>

      <!-- <div class="flex justify-center align-center">
        <div class="absolute">
          <Loader2 class="w-8 h-8 my-4 animate-spin" /> 
        </div>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.carousel,
.carousel-content,
.carousel-item {
  height: 100%;
}

.embla__slide {
  margin-right: 0px;
  /* Default slide gap */
}

@media (min-width: 768px) {
  .embla__slide {
    margin-right: 0px;
    /* Breakpoint SM gap */
  }
}
</style>