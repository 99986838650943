import { defineStore } from 'pinia'

export const useUppyStore = defineStore(
  "UppyStore",
  () => {
    const filesAdded = ref([]);
    const uploadSuccess = ref({});
    const uploadError = ref({});
    const uppy = ref(null);
    const removeFileRequested = ref(false);
    const syncing = ref(false)

    const setFileUploadSuccess = (s: any) => {
      uploadSuccess.value = s;
    };

    const setFileUploadError = (e: any) => {
      uploadError.value = e;
    };

    const setFilesAdded = (files: any) => {
      filesAdded.value = files;
    };
    const setUppy = (uppy: any) => {
      uppy.value = uppy;
    };

    const setRemoveFileRequested = (val: boolean) => {
      removeFileRequested.value = val;
    };

    const setSyncing = (val: boolean) => {
      syncing.value = val;
    };


    return {
      filesAdded,
      setFilesAdded,
      uploadSuccess,
      setFileUploadSuccess,
      uploadError,
      setFileUploadError,
      uppy,
      setUppy,
      removeFileRequested,
      setRemoveFileRequested,
      syncing,
      setSyncing,
    };
  },
  {
    persist: {
      paths: [
        "filesAdded",
        "uploadSuccess",
        "uploadError",
        "removeFileRequested",
      ],
    },
  }
);