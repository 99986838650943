import { onMounted, onUnmounted } from "vue";
import { squircleObserver } from "corner-smoothing";

export function useSquircleObserver() {
  let squircles: any[] = [];

  onMounted(() => {
    
      squircles = Array.from(
        document.querySelectorAll("[class*='squircle-']")
      ).map((el) => {
        const className = el.className;
        const match = className.match(/squircle-(\d+)/);
        const cornerRadius = match ? parseInt(match[1], 10) : 15; // Default to 15 if not found
        return squircleObserver(el, {
          cornerRadius,
          borderWidth: 0,
        });
      });
    
  });

  onUnmounted(() => {
    squircles.forEach(disconnect => disconnect());
  });
}