import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/vue';
import RemoteSources from "@uppy/remote-sources";
import Tus from '@uppy/tus';
import { useUiStore } from '@/stores/uiStore.js';

let uppyInstance: Uppy | null = null;



export default defineNuxtPlugin((nuxtApp) => {
  
  const pinia = nuxtApp.$pinia;
  const uppyStore = useUppyStore(pinia);
  const { setFileUploadSuccess, setFilesAdded, setUppy } = uppyStore;

  if (!uppyInstance) {
    uppyInstance = new Uppy({
      debug: true,
      autoProceed: true,
      onBeforeFileAdded: () => true,
    })
      .use(Tus, {
        endpoint: `https://secure.superseeded.ai/files`,
        async onChunkComplete(req: any) {
          
        },
        onError: function (err) {
          console.log("Error", err);
          console.log("Request", err.originalRequest);
          console.log("Response", err.originalResponse);
        },
      })
      .use(RemoteSources, {
        companionUrl: "https://secure.superseeded.ai/companion",
        companionCookiesRule: 'include',
        sources: [
          "Dropbox",
          "GoogleDrive",
          "GooglePhotos",
          "OneDrive",
          "Box",
          "Url",
        ],
      })
    
    // uppyInstance.on('')
    
    uppyInstance.on('upload-success', (file, response) => {
      
        setFileUploadSuccess({
          file,
          response
        })
    })

    
    uppyInstance.on('files-added', (files) => {
        setFilesAdded(files)
    })



    uppyInstance.on("file-added", (file) => {
      console.log('file-added', file)
      uppyInstance.setFileMeta(file.id, {
        filename: file.name,
        content_type: file.type,
        size: file.size,
      });
    });
    
  }
  setUppy(uppyInstance)
  nuxtApp.vueApp.component("Dashboard", Dashboard);
  nuxtApp.provide('uppy', uppyInstance);
});