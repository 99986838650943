<script lang="ts" setup>
import * as z from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { vAutoAnimate } from '@formkit/auto-animate'
import { Loader2 } from 'lucide-vue-next'


const headers = useRequestHeaders(["cookie"]);
const loading = ref(false)
const invitesLoading = ref(false)
const showImage = ref(false);

const invitedUsers = ref([]);

const uiStore = useUiStore()
const { setInvitedUsers } = uiStore
const { invitedUsers: storedInvitedUsers } = storeToRefs(uiStore)

const schema = z.object({
  invitedUsers: z.array(z.string().email().optional()).optional(),
});


import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)

const truncated = computed(() => {
  return breakpoints.greater("md").value || breakpoints.smallerOrEqual("sm").value
})

watch(invitedUsers.value, () => {
  setInvitedUsers(invitedUsers.value)
})

onMounted(() => {
  invitedUsers.value = storedInvitedUsers.value
})

// define emit for invitedUsers
// const emits = defineEmits(["update:modelValue"])

const {
  handleSubmit,
  meta,
  setErrors
} = useForm({
  validationSchema: toTypedSchema(schema)
});


const {
  errorMessage,
  value,
  errors: invitedUsersError,
} = useField<string[]>("invitedUsers", {
  initialValue: [],
  label: "Invite users",
  validateOnMount: false,
  syncVModel: true,
});

const send = handleSubmit(async (values) => {
  invitesLoading.value = true
  const { data, error } = await useFetch('/api/users/invite', {
    method: 'POST',
    headers,
    body: {
      invitedUsers: values.invitedUsers
    }
  })
  if (error) {
    console.log('error', error)
    setErrors({
      invitedUsers: ['Error inviting users']
    })
  }
  invitesLoading.value = false
  push({
    title: 'Success',
    message: `Invites sent to ${invitedUsers.value.length} users`
  });

})


</script>

<template>
  <FormField v-slot="{ componentField }" name="invitedUsers">
    <FormItem v-auto-animate>
      <FormLabel class="text-xs"></FormLabel>
      <FormControl>
        <!-- <ScrollArea class="!pointer-events-auto w-full rounded-md"> -->
          <p class="text-xs pl-1 pb-1 tracking-tight font-semibold opacity-75">
            Invite People
          </p>
          <TagsInput class="!pointer-events-auto" :delimiters="[32]" v-bind="componentField" :required="true"
            :addOnTab="true" :addOnPaste="true" v-model="invitedUsers">

            <TagsInputItem v-for="item in invitedUsers" :key="item" :value="item">
              <TagsInputItemText />
              <TagsInputItemDelete />
            </TagsInputItem>

            <TagsInputInput id="invited-users" :placeholder="truncated ? 'Invite by email..' : 'Email addresses..'" />


          </TagsInput>
          <span
            class="-top-[39px] relative inline-block float-right px-2 text-muted-foreground opacity-[0.5] whitespace-nowrap tracking-tight font-normal pointer-events-none text-xs">
            Press Enter
            <Icon name="ph:key-return-thin" class="size-6 text-muted-foreground" />
          </span>
        <!-- </ScrollArea> -->
      </FormControl>
      <FormMessage class="font-light text-xs sm:text-base" />
    </FormItem>

  </FormField>
</template>

<style scoped></style>

