<script lang="ts" setup>
import { loadStripe } from "@stripe/stripe-js";
const config = useRuntimeConfig()
const stripe = await loadStripe(config.public.stripe_publishable_key as string);



const uiStore = useUiStore()
const { setCheckingOut } = uiStore
const { checkingOut, stripeClientSecret } = storeToRefs(uiStore)

const checkoutInitialized = ref(false);



const checkout = ref<Stripe.EmbeddedCheckout | null>(null)



const loading = defineModel('loading')


watch(stripeClientSecret, async (val, oldVal) => {
  if (val && oldVal !== val) {
    if (!checkoutInitialized.value) {
      initCheckout()
    }
  }
})


let isInitializing = false;

const initCheckout = async () => {
  if (checkoutInitialized.value || isInitializing) return; // Prevent multiple initializations
  isInitializing = true;
  loading.value = true;
  if (stripeClientSecret.value) {
    checkout.value = await stripe.initEmbeddedCheckout({ clientSecret: stripeClientSecret.value });
  } else {
    isInitializing = false;
    return;
  }
  
  try {
    checkout.value.mount("#checkout");
    checkoutInitialized.value = true;
  } catch (error) {
    checkout.value.destroy();
    checkout.value.mount("#checkout");
    console.error(error);
  } finally {
    isInitializing = false;
  }
};



definePageMeta({
  keepalive: {
    exclude: ['modal']
  },
})

const reminderDates = computed(() => {
  // 7 days before 30 days from now
  return {
    reminder: new Date(Date.now() + (30 - 7) * 24 * 60 * 60 * 1000).toLocaleDateString('en-AU', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    }),
    conversion: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-AU', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    })
  }
})





// watch(checkout, async (val, oldVal) => {
//   if (val && oldVal !== val) {
//     if (checkout.value) {
//       await destroyCheckout();
//     }
//     await nextTick();
//     initCheckout();
//   }
// })


onBeforeMount(() => {
  // check if a subscription event ocurred

  
})


const destroyCheckout = async () => {
  try {
    checkout.value.destroy()
    checkoutInitialized.value = false;
    await nextTick()
  } catch {
    
  }
  }

watch(checkingOut, async (val, oldVal) => {
  if (val && oldVal !== val) {
    if (!val) {
      destroyCheckout()
    } else {
      await destroyCheckout()
      await nextTick()
      initCheckout()
    }
  }
})

watchEffect(async () => {
  if (!checkoutInitialized.value) {
    initCheckout()
  }
})

onBeforeUnmount(async () => {
  console.log('unmounting')
  await destroyCheckout()
  setCheckingOut(false)
})



</script>

<template>
  <div
  class="flex flex-col justify-start sm:justify-center items-start grid-rows-2 grid-cols-1 md:grid-rows-1 sm:grid md:grid-cols-2 grid-flow-col auto-cols-auto auto-rows-auto">
  <!-- <div class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center h-[40vh] w-1/2 z-0">
    <Loader2 class="w-12 h-12 my-4 animate-spin" />
  </div> -->
  


    <div class="!w-full h-fit p-0 order-last sm:order-first">
      
      <div ref="checkOutContainer" id="checkout" :class="['bg-background z-20 pointer-events-auto px-0 sm:px-0 z-10']">
        
        
      </div>
    </div>

    <div class="w-full h-fit mt-0 sm:mt-[0vh] px-0 md:px-5">

      <PlanTimeline  :stripeLoaded="true" />




    </div>
    <!-- <div class="h-[1200px] bg-muted w-[90%] px-10 left-0 top-0  outline rounded-xl z-20">Stripe placeholder</div> -->
  </div>
</template>

<style scoped>
#checkout {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
</style>
