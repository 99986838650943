<script lang="ts" setup>
const route = useRoute()
const userStore = useUserStore()
const { plan } = storeToRefs(userStore)
</script>

<template>
  <GenericDialog :title="false" :footer="false" v-if="route.path !== '/pricing'" width="80%">
    <template #title>

    </template>
    <template #trigger>
      <ProButton class="z-10 mr-0" ref="proButtonRef" />
    </template>
    <template #body>
      <ProTrial v-if="plan" :pro="plan.product.name == 'Basic'" :team="plan.product.name != 'Teams'" />
    </template>
  </GenericDialog>
</template>

<style scoped></style>
