<script lang="ts" setup>
import { vAutoAnimate } from '@formkit/auto-animate'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)

const small = computed(() => breakpoints.smallerOrEqual('sm').value)

const uiStore = useUiStore()
const {triggerPrev} = uiStore
const { selectedInterval, plans, selectedPlan } = storeToRefs(uiStore)


const reminderDates = computed(() => {
  // 7 days before 30 days from now
  return {
    reminder: new Date(Date.now() + (30-7) * 24 * 60 * 60 * 1000).toLocaleDateString('en-AU', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    }),
    conversion: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-AU', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    })
  }

})

const { stripeLoaded } = defineProps({
  stripeLoaded: {
    type: Boolean,
    default: false
  }
})

</script>

<template>
  <div class="w-full h-fit flex justify-center sm:justify-center items-center sm:scale-100 origin-center">

    <div id="gifts" class="flex flex-col justify-center items-center w-[90%] mt-10 ">
      <p class="pb-2 text-2xl md:text-3xl font-semibold tracking-tight transition-colors first:mt-0 flex self-start">
      <div class="-ml-2">
        <Button @click="triggerPrev()" variant="text" size="14" class="pointer-events-auto mr-2 relative">
          <Icon class="size-8" name="material-symbols-light:chevron-left">

          </Icon>
        </Button>
      </div>
      {{ selectedPlan?.name == 'Pro' ? 'Try SuperSeeded Pro for free' : 'Start your team\'s free trial' }}
      </p>


      <div v-auto-animate class="w-full">
        <ul class="my-2 md:my-3 list-none w-full text-sm md:text-base">
          <li class="flex items-start gap-2 mt-2">
            <Icon name="ic:round-done" class="w-4 h-4 flex-shrink-0 mt-0.5" />
            <span>Enjoy all upgraded features together</span>
          </li>

          <li v-if="selectedPlan?.name == 'Teams'" class="flex items-start gap-2 mt-2">
            <Icon name="ic:round-done" class="w-4 h-4 flex-shrink-0 mt-0.5" />
            <span>
              Flat rate of {{ 'AUD $' + selectedPlan.prices[selectedInterval].unit_amount/100 }} for the first 3 people,
              {{ 'AUD $' + selectedPlan.prices[selectedInterval].unit_amount/100/3 }} per additional person
            </span>
          </li>

          <li class="flex items-start gap-2 mt-2">
            <Icon name="ic:round-done" class="w-4 h-4 flex-shrink-0 mt-0.5" />
            <span>SuperSeeded Pro is free for 30 days</span>
          </li>
          <li class="flex items-start gap-2 mt-2">
            <Icon name="ic:round-done" class="w-4 h-4 flex-shrink-0 mt-0.5" />
            <span>Export your data anytime</span>
          </li>
        </ul>
      </div>

      <ul
        class="py-0 md:py-8 list-none [&>li]:pt-[20px] sm:[&>li] :pt-[20px] [&>li]:flex [&>li]:items-center [&>li]:gap-2 -ml-4 md:-ml-0">

        <!-- <div class="absolute left-30 ml-[19px] mt-14 sm:mt-10 w-1 h-[180px] bg-primary/20">
          <div class="absolute w-full h-[30%] bg-foreground"></div>
        </div> -->
        <li class="border-1 border-primary">
          <Icon name="ph:gift-light" size="48" class="p-2 bg-background relative rounded-full mr-2 z-10" />
          <div class="flex w-full flex-col">
            <p class="text-sm tracking-tight">
              Today - Free trial for 30 days. Cancel anytime.
            <div
              class="absolute -ml-[38px] z-0 bg-gradient-to-b from-foreground from-[24%] via-primary/20 via-[25%] via-primary/20 via-[80%] to-transparent to-[100%] h-[10vh] w-1">
            </div>

            </p>
            <p class="text-xs text-muted-foreground">Start your free SuperSeeded trial and get our most-loved
              features.</p>
          </div>
        </li>
        <li class="border-1 border-primary">
          <Icon name="material-symbols-light:notifications-outline" size="48"
            class="p-2 bg-background relative rounded-full mr-2" />
          <div class="flex w-full flex-col">
            <p class="text-sm tracking-tight">{{ reminderDates.reminder }}</p>
            <p class="text-xs text-muted-foreground">We'll send you a reminder 7 days before your SuperSeeded
              trial ends.</p>
          </div>
        </li>
        <li class="border-1 border-primary">
          <!-- <Icon name="ph:gift-light" size="48" class="mr-2" /> -->
          <img src="/public/favicon-32x32.png" class="bg-background p-2 w-10 h-10 ml-[2px] z-10" />
          <div class="flex w-full flex-col pl-2">
            <p class="text-sm tracking-tight">{{ reminderDates.conversion }}</p>
            <p class="text-xs text-muted-foreground">
              Your subscription starts, unless you've canceled during the trial.</p>
          </div>
        </li>
      </ul>

      <div v-if="true" class="flex flex-col items-start mt-5 md:mt-12 xs:block">
        <p class="leading-7 [&:not(:first-child)]:mt-6 font-bold text-left text-xs">
          Mat Plummer
        </p>
        <p class="muted-foreground text-left text-xs">
          Managing Director, Evergreen Connect
        </p>

        <blockquote class="mt-3 text-sm italic">
          "After all," he said, "everyone enjoys a good joke, so it's only fair that they should pay for the
          privilege."
        </blockquote>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
