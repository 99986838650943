<script setup lang="ts">
import * as z from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { v4 as uuidv4 } from 'uuid';
import { Loader2 } from "lucide-vue-next";
import { useBreakpoints, useFocus } from '@vueuse/core';
import { vAutoAnimate } from '@formkit/auto-animate'
const client = useSupabaseClient()
const userStore = useUserStore();
const uiStore = useUiStore()

const { setTeams, setSelectedTeam, setUser } = userStore;
const { teams, selectedTeam, plan, user } = storeToRefs(userStore);

const headers = useRequestHeaders(["cookie"]);
const {toggleDialog} = uiStore


const inviteLink = ref('');


const personalTeam = computed(() => {
  return {
    id: user.value?.id,
    metadata: {
      name: user.value?.email || 'Personal',
      type: "Personal",
      description: "Personal account",
    },
  };
});

const sendingInvitations = ref('')

const sendInvitations = async () => {
  sendingInvitations.value = 'pending'
  
  // // use resend api to send out invites
  const { data, error, status } = await useFetch('/api/invite/send', {
    method: 'POST',
    headers,
    body: {
      team_name: team_name.value,
      invited_users: invitedUsers.value,
      invite_links: inviteLinks.value,
      user: user.value
    }
  })
  sendingInvitations.value = 'sent'

  if (status.value == 'success') {
    sendingInvitations.value = 'delivered'
  }
  
}

// const { x, y, isScrolling, arrivedState, directions } = useScroll(scrollpane)

// watch(y.value, (newVal, oldVal) => {
//     console.log(newVal, oldVal)
// })

// const user = ref({ display_name: 'Edan Weconst inviteLink = ref('');is', email: 'edanweis@gmail.com' })

// watch teams
// watchEffect(async () => {
//     if (teams?.value !== userStore?.teams?.value) {
//         setTeams(userStore?.teams?.value);
//     }
// })

// watch the user object for 'subscription_status' change

watch(selectedTeam, (newVal, oldVal) => {
  
})

const alertData = ref({})
const showAlert = ref(false)


const showAlertMessage = (team, action) => {
  alertData.value = { ...team, action }
  showAlert.value = true
}


const archived = computed(() => [
  {
    teams: teams.value || [], // Assuming teams.value has a 'teams' array
    filter: (team) => team.metadata.archived,
    subMenu: [
      { label: {owner:'Restore', member: 'Restore'}, icon: 'lucide:archive-restore', action: (team) => { toggleArchiveTeam(team) } },
      { label: {owner:'Delete', member: 'Leave'}, icon: 'lucide:trash', action: (team) => { leaveOrDeleteTeam(team) } },
    ]
  }
])
const groups = computed(() => [
  {
    label: "Personal Account",  
    teams: [personalTeam.value],
        filter: () => true,
    subMenu: [
      { label: { owner: 'Settings', member: 'Settings' }, icon: 'lucide:settings', action: (team) => { navigateTo('/account'); subMenuActive.value = false; open.value = false } }
    ]
  },
  {
    label: "Teams",
    teams: teams.value || [], // Assuming teams.value has a 'teams' array
      filter: (team) => !team.metadata.archived,
      subMenu: [
          { label: {owner:'Mute', member: 'Mute'}, icon: 'lucide:volume-x', action: (team) => { muteTeam(team) } },
          { label: {owner:'Archive', member: 'Archive'}, icon: 'lucide:archive', action: (team) => {toggleArchiveTeam(team)} },
        { label: { owner: 'Delete', member: 'Leave'}, icon: 'lucide:trash', action: (team) => { leaveOrDeleteTeam(team) } },
      ]
  }
]);

const open = ref(false);
const showNewTeamDialog = ref(false);
const showTeamMenu = ref(false);
const subMenuActive = ref(false);
const currentTab = ref("trial");
const selectedUseCase = ref("");
const inputTeamName = ref("");
const isActive = computed(() => user?.value?.app_metadata?.subscription_status === 'active');
const isTeamsPlan = computed(() => plan.value?.product?.name == 'Teams');
currentTab.value = isActive.value && isTeamsPlan.value ? 'use' : 'trial'

const submitCallback = ref(null)
const inviteLinks = defineModel('inviteLinks');


// if (!selectedTeam.value ){
//     setSelectedTeam(groups.value[0].teams[0]);
//   }

const totalTeams = computed(() => {
  return groups.value.reduce((sum, group) => sum + group.teams.length, 0)
})


const resetTab = () => {
  currentTab.value = isActive.value && isTeamsPlan.value ? 'use' : 'trial'
}

const useCases = [
  {
    id: "landscape_architect",
    label: "Landscape Architecture",
    desc: "Faster planting, beautifully presented",
    image: "https://ik.imagekit.io/8qxqs9cznn/general/tr:w-0.5/out-0%20(8).webp?updatedAt=1724725303999"
  },
  {
    id: "nursery",
    label: "Nursery Supply",
    desc: "Better forecasting, quicker quotes",
    image: "https://ik.imagekit.io/8qxqs9cznn/general/tr:w-0.5/out-0%20(10).webp?updatedAt=1724727073472"
  },
  {
    id: "gov_research",
    label: "Gov/Research",
    desc: "Better outcomes, a lighter inbox",
    image: "https://ik.imagekit.io/8qxqs9cznn/general/tr:w-0.5/out-1%20(9).webp?updatedAt=1724727436098"
    
  },

  {
    id: "personal",
    label: "Personal",
    desc: "Refine my planting pallet",
    image: "https://ik.imagekit.io/8qxqs9cznn/general/out-0%20(11).webp?updatedAt=1724727725258"
  },
];

const handleKeydown = (event) => {
  const ignoreKeys = ["a", "A"];
  if (ignoreKeys.includes(event.key)) {
    event.preventDefault(); // Prevents the key action
  } else {
    // event.stopPropagation();
  }
};





const schema = z.object({
  name: z.string().min(1, "Team name is required"),
  desc: z.string().optional(),
  invitedUsers: z.union([
    z.array(z.string().email()),
    z.string().optional().refine(val => val === '', { message: "Invalid value" })
  ]).optional(),
});

const {
  handleSubmit,
  meta,
  setErrors,
  error: formError,
} = useForm({
  validationSchema: toTypedSchema(schema),
});

// const invitedUsers = ref<string[]>([]); // Define as an array of strings
const invitedUsers = ref([]);

const {
  errorMessage,
  value,
  errors: invitedUsersError,
} = useField<string[]>("invitedUsers", {
  initialValue: [],
  label: "Invite users",
  validateOnMount: false,
  syncVModel: true,
});

watch(showNewTeamDialog, (newVal, oldVal) => {
  if (!newVal && oldVal) {
    // newVal is false when the dialog is closed
    resetTab()
    selectedUseCase.value = "";
    sendingInvitations.value = ''
    submitCallback.value = null
  }
  if (newVal) {
    inviteLink.value = null
    inviteLinks.value = null
    sendingInvitations.value = ''
    submitCallback.value = null
  }
});

// create createTeamPending computed prop
const createTeamPending = computed(() => {
  return createTeamPending.value;
});

// const refreshTeams = async () => {
//   const { data: newUser, refresh } = useFetch('/api/users/user')
//   setUser(newUser.value)
//   const teams = await await fetchUserData(headers).teams();
//   setTeams(teams);
//   commandKey.value++;
// };

const commandKey = ref(0);

const toggleArchiveTeam = async (team) => {
  if (selectedTeam.value == team) {
    setSelectedTeam(groups.value[0].teams[0]);
  }
  
  await updateMetadata(team, {archived: !team?.metadata?.archived})
  
  
};

const updateMetadata = async (team: any, newMetadata: any) => {

  let mergedMetadata = { ...team.metadata, ...newMetadata }
  //update store team
  setTeams(teams?.value.map(t => t.id === team.id ? { ...t, metadata: mergedMetadata } : t)
  );

  
  const { data, error: metadataError } = await client
    .from('groups')
    .select()
    .match({ 'id': team.id });


  const { error: updateMetadataError } = await client
    .from('groups')
    .update({ metadata: mergedMetadata })
    .match({ 'id': team.id })

  if (updateMetadataError) {
    console.log('updateMetadataError', updateMetadataError)
  }
}



const muteTeam = async (team) => {
  await updateMetadata(team, {muted: !team?.metadata?.muted})
};

const checkGroup = ref("");
const isTeamOwner = ref(false);


watch(checkGroup, async (newVal) => {
  isTeamOwner.value = user.value.app_metadata.groups?.[newVal]?.includes("owner")
  
});

const leaveOrDeleteTeam = async (team) => {

  const remainingGroups = Object.entries(user.value.app_metadata.groups).filter(([key, roles]) => roles.includes("owner")).map(([key]) => key);
  // check if team is the only group where they are "owner", get all groups, then filter where "owner" is present, then see if the list length is 1
  if (remainingGroups.length == 1) {
    // backup the object
    let t = { teams: [...teams?.value] }

    const { error: updateMetadataError } = await client
      .from("groups")
      .update({ "metadata": { "provisioned": false } })
      .eq("id", team.id)

    if (updateMetadataError) {
      setTeams(t)
    }
    // remove from state
    setTeams([])
  } else {
    // check if owner
    if (isTeamOwner.value) {
      showAlertMessage(team, 'delete')
    } else {
      showAlertMessage(team, 'leave')
    }
  }

  
  
  
};

const goToTeam = async (id) => {
  await navigateTo({
    path: `/team/${id}`,
    params: {
      'intent': 'onboard'
    }
  });
  showNewTeamDialog.value = false;
  setSelectedTeam(teams.value.find(t => t.id == id))
};


const deletePending = ref(false);

const leaveTeam = async (team) => {
  deletePending.value = true;
  console.log(`dleteing user.value.id ${user.value.id} from team ${team.id}`)
  const { data, error } = await client.from('group_users')
      .delete()
      .eq('group_id', team.id)
      .eq('user_id', user.value.id)    
  deletePending.value = false;
    showAlert.value = false
    push.success({
      title: "Success! Team left",
      message: `${team.metadata.name} team left successfully`,
    });
  
  
  inputTeamName.value = ''
}

const deleteTeam = async (team: any, transfer: boolean) => {
  deletePending.value = true;
  if (!transfer) {
    const { data, error } = await useAsyncData('groups', async () => {
      await client.from('group_users').delete().eq('group_id', team.id)
      await client.from('groups').delete().eq('id', team.id)
    })
    // if (error) {
    //   console.log(error)
    //   push.success({
    //     title: 'Error',
    //     message: `Error leaving team: ${error}`,
    //   })
    // } else {
    showAlert.value = false
    deletePending.value = false
      push.success({
        title: "Success! Team deleted",
        message: `${team.metadata.name} team deleted successfully`,
      });
    // }

    setTeams(teams.value.filter(t => t.id != team.id))
    inputTeamName.value = ''
  }
}


const team_name = ref(null)

const onSubmit = handleSubmit(async (values) => {
  currentTab.value = "invite";
  if (!meta.value.valid) {
    setErrors(formError);
    return;
  }

  team_name.value = values.name

  const invited_users = values.invitedUsers ? values.invitedUsers.map(email => ({ id: uuidv4(), email })): []
  try {
    const {
      data,
      pending: createTeamPending,
      error,
    } = await useFetch("/api/groups/create", {
      method: "POST",
      headers,
      body: {
        invited_by: user?.value?.id,
        roles: ["admin"],
        group_name: values.name,
        group_desc: values.desc,
        invited_users,
        use_case: selectedUseCase.value,
        provisioned: true, // we cannot be provisioned until we have a valid stripe customer, assumed to be true because we are able to create a team
        
      },
    });

    if (error) {
      // commented out because it seems to alway be in err
      // navigateTo(error.data.redirectTo || '/pricing')
      // close dialog
      // showNewTeamDialog.value = false
    }
    
    
    // console.log(`${window.location.origin}/invite/${toRaw(data).value.data}`)
    let link = `${window.location.origin}/invite/${toRaw(data).value.data.id}`

    submitCallback.value = data.value
    
    
    inviteLinks.value = data.value.data.encrypted.map(e => {
      return {
        link: `${window.location.origin}/invite/${e}`,
        code: data.value.data.id
      }
    })

    push.success({
      title: "Success! Team created",
      description: `${values.name} team created successfully`,
    });

    inviteLink.value = link
    
    
    


    
  } catch (err) {
    // check if err is not an empty object
    if (err != {}) {
      console.log(err)
      push.error({
        title: "Success! Team created",
        description: `${values.name} team created successfully`,
      });
    }
    
  }
});


const filterFunction = (groups, term) => {
  if (!term) return groups;
  term = term.toLowerCase();
  return groups
    .slice(0, -1)
    .filter((group) => group.metadata.name.toLowerCase().includes(term));
};
</script>

<template #default>
  <form :key="commandKey" @submit.prevent="onSubmit">
    <Dialog :key="commandKey" v-model:open="showNewTeamDialog">
      <Command :key="commandKey" :class="['min-h-fit flex flex-col justify-between', { 'opacity-100': subMenuActive }]" :filter-function="filterFunction">
        <CommandList :style="{'min-height': `${(162)+ (totalTeams * 32)}px`}" :class="['overflow-y-hidden']">
          <CommandInput placeholder="Search team..." />
          <CommandEmpty>No teams found.</CommandEmpty>
          <!-- <PerfectScrollbar> -->
          <TeamGroups v-model:isTeamOwner="isTeamOwner" v-model:check-group="checkGroup" :groups="groups"
            :selectedTeam="selectedTeam" />
            <!-- </PerfectScrollbar> -->
          <Accordion v-if="archived[0].teams.filter(e => e.metadata.archived).length > 0" type="single" collapsible
            class="no-chevron">
            <AccordionItem value="item-1">
              <AccordionTrigger>
                <p
                  class="-m-8 text-xs bg-muted rounded p-1 px-2 !no-underline text-muted-foreground font-semibold ml-0 !no-underline w-[95%]">
                  <Icon name="lucide:archive" class="mr-2" />
                  <span class="!no-underline">Archived</span>
                </p>
              </AccordionTrigger>
              <AccordionContent class="rounded">
                <TeamGroups v-model:isTeamOwner="isTeamOwner" v-model:check-group="checkGroup" :groups="archived"
                  :selectedTeam="selectedTeam" class="pb-10" />
              </AccordionContent>
            </AccordionItem>
          </Accordion>


        </CommandList>

        <CommandSeparator />
        
        <CommandList>
          <CommandGroup>
            <DialogTrigger @keydown.stop as-child>
              <CommandItem class="cursor-pointer" value="create-team" @select="
                  () => {
                    open = false;
                    showNewTeamDialog = true;
                  }
                ">
                <Icon name="lucide:plus" class="mr-2 h-5 w-5" />
                New Team

              </CommandItem>
            </DialogTrigger>
          </CommandGroup>

        </CommandList>
      </Command>

      <DialogContent :trapFocus="false"
        class="w-[80%] min-w-[80%] min-w-[90vw] sm:min-w-[85vw] dialog-shadow mt-0 pt-0 !border-0 gap-0 rounded-xl w-fit sm:max-w-[190vw] md:max-w-[190vw] lg:max-w-[80vw] transition-all duration-180 p-8 shadow-xxl">
        <ProTrial :team="true" v-if="currentTab == 'trial'" />
        <div v-else class="p-8">
          <div v-if="currentTab == 'use'" class="mb-5">
            <DialogTitle>
              <p class="scroll-m-20 text-xl font-semibold tracking-tight">
                How do you want to use SuperSeeded?
              </p>
              <p class="text-sm text-muted-foreground">
                This helps us customise your experience.
              </p>
            </DialogTitle>

            <div class="my-10">
              <RadioGroup default-value="option-one" class="flex justify-around space-x-1">
                <div v-for="option in useCases" :key="option.id"
                  class="justify-between cursor-pointer items-center w-full">
                  <RadioGroupItem :id="option.id" :value="option.id" class="hidden" />
                  <div :class="[
                    'min-h-24 rounded-md flex items-center  w-full relative overflow-hidden group transition-all',
                    {
                      'shadow-xl hover:border-transparent':
                          selectedUseCase == option.id,
                    },
                  ]" @click="() => { selectedUseCase = option.id; }">
                    <div :class="[
                      'absolute opacity-100 inset-0 bg-fill bg-center bg-no-repeat transition-all ease-in-out transform group-hover:scale-105 duration-[time:20ms]',
                      `bg-[url('${option.image}')]`,
                      { 'opacity-100': selectedUseCase == option.id }
                    ]"></div>
                    <div :class="['absolute inset-0 opacity-100  group-hover:opacity-80 transition-all', { 'bg-foreground': selectedUseCase !== option.id }]"></div>
                    <Label :class="[
                      'cursor-pointer text-center w-full relative z-10 font-semibold text-lg transition-colors',
                      { 'text-white drop-shadow-lg': selectedUseCase == option.id },
                      'text-white'
                    ]" :for="option.id">{{ option.label }}</Label>
                      
                    </div>
                    
                  
                </div>
              </RadioGroup>
            </div>
          </div>

          <div v-if="currentTab == 'form'" class="mb-5">
            <DialogTitle>
              <p class="scroll-m-20 text-xl font-semibold tracking-tight">
                Create team
              </p>
              <p class="text-sm text-muted-foreground">
                Manage access to shared resources among team members.
              </p>
            </DialogTitle>

            <div class="space-y-4 pt-8 pb-4">
              <div class="space-y-2">
                <Label for="owner">Owner</Label>
                <TooltipProvider :delayDuration="0">
                  <Tooltip>
                    <TooltipTrigger><Input id="owner" disabled :placeholder="user?.display_name || user?.email" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        You are automatically team owner, as {{ user?.role }}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div class="space-y-2">
                <FormField v-slot="{ componentField }" name="name">
                  <FormItem v-auto-animate>
                    <FormLabel>Team Name</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="Team name" v-bind="componentField" />
                    </FormControl>
                    <FormMessage class="font-light" />
                  </FormItem>
                </FormField>
              </div>

              <div class="space-y-2">
                <FormField v-slot="{ componentField }" name="desc">
                  <FormItem v-auto-animate>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea v-bind="componentField" placeholder="Eg: Capital works L2, STMP, Estate 22 " />
                    </FormControl>
                    <FormMessage class="font-light" />
                  </FormItem>
                </FormField>
              </div>

              <!-- invites -->
              <div class="space-y-2">
                <FormField v-slot="{ componentField }" name="invitedUsers">
                  <FormItem v-auto-animate>
                    <FormLabel>Invite members</FormLabel>
                    <FormControl>
                      <TagsInput :delimiters="[32]" v-bind="componentField" :required="true" :addOnTab="true"
                        :addOnPaste="true" v-model="invitedUsers">
                        <TagsInputItem v-for="item in invitedUsers" :key="item" :value="item">
                          <TagsInputItemText />
                          <TagsInputItemDelete />
                        </TagsInputItem>
                        <TagsInputInput placeholder="Email addresses ← Tab/Enter to add more" />
                      </TagsInput>
                    </FormControl>
                    <FormMessage class="font-light" />
                  </FormItem>
                </FormField>
              </div>

              <!-- invite link -->

              <div class="space-y-2"></div>
            </div>
          </div>

          <div v-if="currentTab == 'invite'">
            <DialogTitle>
              <p class="scroll-m-20 text-xl font-semibold tracking-tight">
                You're team is ready.
              </p>
              <p class="text-md font-normal text-muted-foreground leading-7 [&:not(:first-child)]:mt-3">
                Send out invites to your team members.
              </p>
            </DialogTitle>
            <div class="space-y-4 pt-8 pb-4">
              <div class="space-y-2">
                <div class="space-y-2 mt-">
                  <div class="text-xs">
                    
                  </div>
                  <!-- <Label for="invite-link">invite Link</Label> -->
                  <InviteLinks :invites="inviteLinks" />
                  <p class="text-sm text-muted-foreground">
                    {{ inviteLinks ? `Share ${inviteLinks.length > 1 ? 'these' : 'this'} single-use invite link${
                    inviteLinks.length > 1 ? 's' : '' } with your team. One invitation per user.` : 'Getting those invites ready...' }}
                  </p>

                </div>
              </div>
            </div>

          </div>
        </div>

        <DialogFooter v-if="currentTab != 'trial'" >
          <div class="p-8">
            <div v-if="currentTab == 'use'">
              <div class="space-x-2 flex justify-end">
                <Button variant="ghost" @click="showNewTeamDialog = false">
                  Cancel
                </Button>
                <Button :disabled="selectedUseCase == ''" v-if="true" @click.stop="currentTab = 'form'">
                  Next
                </Button>
                <Button v-else class="pl-3 subscribe whitespace-nowrap">
                  <Icon size="32" name="streamline:nature-ecology-rice-field-sun-rise-set-field-crop-produce-farm"
                    class="w-4 h-4 mr-4 -mt-1" />
                  <span class="text">Pro</span>
                  <span class="shimmer"></span>
                </Button>
              </div>
            </div>
            <div v-if="currentTab == 'form'" class="flex justify-end">
              <div class="space-x-2 flex">
                <Button variant="ghost" @click="currentTab = 'use'">
                  Back
                </Button>
                <Button @click="onSubmit" type="submit" :disabled="createTeamPending || !meta.valid">
                  <Loader2 v-if="createTeamPending" class="w-4 h-4 mr-2 animate-spin" />
                  Create
                </Button>
              </div>
            </div>

            <div v-if="currentTab == 'invite'" class="flex mt-6 space-x-2">
              <!-- skip goes to the team id from form values -->
              
              <Button v-if="sendingInvitations==''" variant="ghost" @click="goToTeam(submitCallback.group_id)">
                Skip
              </Button>
              
                <Button v-if="invitedUsers.length > 0" :disabled="!inviteLink" @click="sendInvitations" :class="[{'pointer-events-none !cursor-default': sendingInvitations!=''}]">
                  <Loader2 v-if="sendingInvitations == 'pending'" class="w-4 h-4 mr-2 animate-spin" />
                  
                  
                  {{ sendingInvitations=='delivered' ? 'Sent' : sendingInvitations=='pending' ? 'Sending' : 'Send' }} {{ invitedUsers.length }} {{ invitedUsers.length > 1 ? 'invitations' : 'invitation' }}
                  
                  <Icon v-if="sendingInvitations=='sent'" name="ic:round-done" class="ml-2 w-5 h-5 text-muted-foreground" />

                  <Icon v-if="sendingInvitations=='delivered'" name="material-symbols:done-all-rounded" class="ml-2 w-5 h-5 text-sky-400" />
                  
                </Button>
                <Button v-if="sendingInvitations=='sent' || sendingInvitations=='delivered'" variant="ghost" @click="goToTeam(submitCallback.group_id)">
                    Go to team <Icon name="ic:round-arrow-forward" class="ml-2 w-5 h-5" />
                  </Button>
              
            
          
          </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>


  </form>
  <AlertDialog v-model:open="showAlert">
    <AlertDialogContent class="border-0 shadow-xxl">
      <AlertDialogHeader>
        <AlertDialogTitle class="text-xl font-semibold tracking-tight text-destructive">{{ alertData.action == 'delete'
          ? 'Delete' : 'Leave' }} {{
          alertData.metadata.name }} ?
        </AlertDialogTitle>
        <AlertDialogDescription class="text-primary text-left">

          You are about to {{ alertData.action == 'delete' ? 'delete' : 'leave' }} a team.
          <span v-if="alertData.action == 'delete'">
            You and all other team members will lose access to {{ alertData.metadata.name
            }} permanently.
          </span>
          <span v-else>
            You will lose access to {{ alertData.metadata.name
            }}. You can always rejoin the team by using the invite link sent to you.
          </span>
          <div class="mt-4" v-if="alertData.action == 'delete'">
            <p class="text-primary mb-4">Enter the team name <span class="underline">{{ alertData.metadata.name
                }}</span> to confirm deletion:</p>
            <Input type="text" v-model="inputTeamName" :placeholder="`${alertData.metadata.name}`"
              class="w-full"></Input>
          </div>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <Button @click="showAlert = false" variant="ghost">Cancel</Button>
        <Button class="" v-if="alertData.action == 'delete'"
          :disabled="inputTeamName != alertData.metadata.name || deletePending" variant="destructive"
          @click="deleteTeam(alertData)">
          <Loader2 v-if="deletePending" class="w-4 h-4 mr-2 animate-spin" />
          Delete
        </Button>
        <Button v-else class="" @click="leaveTeam(alertData)"
          :disabled="deletePending">
          <Loader2 v-if="deletePending" class="w-4 h-4 mr-2 animate-spin" />
          Leave
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style>

.no-chevron svg{
  display:none;
}

.shadow-inside {
  box-shadow:
    inset 0px -20px 20px -20px rgba(0, 0, 0, 0.2),
    inset 0px -10px 10px -10px rgba(0, 0, 0, 0.2);
}
</style>
