<script lang="ts" setup>
const { colorMode } = storeToRefs(useUiStore())
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)

const truncated = computed(() => breakpoints.smallerOrEqual("sm").value)

const proButtonRef = ref(null);

const route = useRoute()
onMounted(async () => {
  if (route.query.subscribe) {
    proButtonRef.value.$el.click()
  }
});

</script>

<template>
  <Button ref="proButtonRef" class="-ml-20 sm:ml-0 subscribe whitespace-nowrap scale-[0.6] sm:scale-75 origin-left -mr-20">
    <Icon size="32" name="streamline:nature-ecology-rice-field-sun-rise-set-field-crop-produce-farm"
      class="w-4 h-4 mr-4 -mt-1" />
    <span :class="{ 'text-dark': colorMode === 'dark', 'text-light': colorMode === 'light' }"
      v-text="truncated ? 'Try PRO' : 'Try PRO for 30 days'">

  </span>
  <span class=" shimmer"></span>
  </Button>
</template>

<style>
:root {
  --glow-hue: 222deg;
  --shadow-hue: 180deg;
  --spring-easing: linear(0, 0.002, 0.01 0.9%, 0.038 1.8%, 0.156, 0.312 5.8%, 0.789 11.1%, 1.015 14.2%,
      1.096, 1.157, 1.199, 1.224 20.3%, 1.231, 1.231, 1.226, 1.214 24.6%,
      1.176 26.9%, 1.057 32.6%, 1.007 35.5%, 0.984, 0.968, 0.956, 0.949 42%,
      0.946 44.1%, 0.95 46.5%, 0.998 57.2%, 1.007, 1.011 63.3%, 1.012 68.3%,
      0.998 84%, 1);
  --spring-duration: 1.33s;
}

@property --shimmer {
  syntax: "<angle>";
  inherits: false;
  initial-value: 33deg;
}

@keyframes shimmer {
  0% {
    --shimmer: 0deg;
  }

  100% {
    --shimmer: 360deg;
  }
}

@keyframes shine {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes text {
  0% {
    background-position: 100% center;
  }

  100% {
    background-position: -100% center;
  }
}

button.subscribe {

  font-weight: 600;
  /*     background-image: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5); */

  padding: .8em 1.4em;
  position: relative;
  isolation: isolate;

  border-radius: 0.66em;
  scale: 1;
  transition: all var(--spring-duration) var(--spring-easing);
}

button.subscribe:hover:not(:active),
button.subscribe.active {
  transition-duration: calc(var(--spring-duration)*0.5);
  scale: 1;
  box-shadow: 0 4px 8px -2px hsl(var(--glow-hue) 50% 20% / 50%), inset 0 0 0 transparent;
}

button.subscribe:active {
  scale: 1;
  transition-duration: calc(var(--spring-duration)*0.5);
}

.shimmer {
  position: absolute;
  inset: -40px;
  border-radius: inherit;
  mask-image: conic-gradient(from var(--shimmer, 0deg),
      transparent 0%,
      transparent 10%,
      black 36%,
      black 45%,
      transparent 50%,
      transparent 60%,
      black 85%,
      black 95%,
      transparent 100%);
  mask-size: cover;
  mix-blend-mode: plus-lighter;
  animation: shimmer 1s linear infinite both;
}

button.subscribe:hover .shimmer::before,
button.subscribe:hover .shimmer::after,
button.subscribe.active .shimmer::before,
button.subscribe.active .shimmer::after {
  opacity: 1;
  animation: shine 1.2s ease-in 1 forwards;
}

.shimmer::before,
.shimmer::after {
  transition: all 0.5s ease;
  opacity: 0;
  content: "";
  border-radius: inherit;
  position: absolute;
  mix-blend-mode: color;
  inset: 40px;
  pointer-events: none;
}

.shimmer::before {
  box-shadow: 0 0 3px 2px hsl(var(--glow-hue) 20% 95%),
    0 0 7px 4px hsl(var(--glow-hue) 20% 80%),
    0 0 13px 4px hsl(var(--glow-hue) 50% 70%),
    0 0 25px 5px hsl(var(--glow-hue) 100% 70%);
  z-index: -1;
}

.shimmer::after {
  box-shadow: inset 0 0 0 1px hsl(var(--glow-hue) 70% 95%),
    inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
    inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
  z-index: 2;
}


button.subscribe .text-dark {
  color: transparent;
  background-clip: text;
  background-color: black;
  background-image: linear-gradient(120deg, transparent, hsla(var(--glow-hue), 100%, 80%, 0.66) 40%, hsla(var(--glow-hue), 100%, 90%, .9) 50%, transparent 52%);
  background-repeat: no-repeat;
  background-size: 300% 300%;
  background-position: center 200%;
}

button.subscribe .text-light {
  color: transparent;
  background-clip: text;
  background-color: white;
  background-image: linear-gradient(120deg, transparent, hsla(var(--glow-hue), 100%, 80%, 0.66) 40%, hsla(var(--glow-hue), 100%, 90%, .9) 50%, transparent 52%);
  background-repeat: no-repeat;
  background-size: 300% 300%;
  background-position: center 200%;
}


button.subscribe:hover .text,
button.subscribe.active .text {
  animation: text .66s ease-in 1 both;
}

</style>
