import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/pages/runtime/validate.js";
import _03_45catch_45all_45global from "/vercel/path0/middleware/03.catch-all.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _03_45catch_45all_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/middleware/auth.ts")
}