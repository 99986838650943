<script lang="ts" setup>
import { Loader2 } from 'lucide-vue-next'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)
const smallbuttons = computed(() => breakpoints.smallerOrEqual("sm").value)
const uiStore = useUiStore()
const { setCheckingOut, setSelectedPlan } = uiStore

const { plan, disableButton } = defineProps({
  plan: {
    type: Object,
    required: true,
  },
  disableButton: {
    type: Boolean,
    required: false,
  },
})

</script>

<template>
  <div v-if="plan" class="max-w-64 sm:max-w-80 ">

    <MultiInput v-if="plan.name === 'Teams'" />

    <div class="sm:min-h-[8.6rem]" v-else></div>

    <Button @click="setSelectedPlan(plan)" class="w-full text-xs py-1.5 sm:py-0 whitespace-nowrap mt-10
      pointer-events-auto" type="submit" :variant="plan.name === 'Pro' ? 'outline' : ''"
      :size="smallbuttons ? 'xs' : 'sm'" :disabled="disableButton">
      <Loader2 v-if="disableButton" class="w-4 h-4 mr-2 animate-spin" />
      {{ plan.name === 'Pro' ? 'Start my free trial' : 'Start my team\'s free trial' }}
    </Button>
    <p class="pt-2 text-[0.6rem] md:text-[0.7rem] text-muted-foreground w-full  leading-tight text-left ">
      Cancel
      anytime, we'll
      remind you when your free trial ends</p>


  </div>
</template>

<style scoped></style>
