export default defineNuxtRouteMiddleware((to, from) => {

    const matchedRoutes = to.matched;
    if (matchedRoutes.length === 0) {
        // No routes matched, handle as needed
        return navigateTo('/'); // Redirect to home or any other page
    }
    return
});

