import { defineStore } from 'pinia'
export const useProjectStore = defineStore('ProjectStore', () => {

    const projects = ref<any[]>([])
    const selectedProject = ref<any>(null)
    const syncStatus = ref('saved')
    
    const setSyncStatus = (status: string) => {
        syncStatus.value = status
    }

    const setProjects = async (data: any[]) => {
        projects.value = data
    }

    const setSelectedProject = (data: any) => {
        selectedProject.value = data
    }

    const addToProjects = async (data: any) => {
        // if project is not in projects, then add it
        if (!projects.value.includes(data)) {
            projects.value.push(data)
        }
    }
    
    
    return {
        projects, 
        setProjects,
        addToProjects,
        selectedProject,
        setSelectedProject,
        syncStatus,
        setSyncStatus
    }

}, {
    persist: {
    paths: [],
}})
