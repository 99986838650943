<template>
    <!-- <header :style="styles" class="Header fixed top-0 w-full p-4 flex items-center gap-2 -md z-50" ref="headerRef"> -->
        

        <header ref="headerRef" :class="['flex h-16 items-center xs:gap-0 sm:gap-4 sm:px-4 md:px-6 pl-0 sm:pl-1 pr-2 z-50 dark:bg-muted',{'bg-muted': route.path !== '/'} ]">
      <!-- <nav class="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6"> -->

          <slot name="menu" />
          
          <div class="flex-1" />
          <Button variant="text" size="icon" @click="colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'" class="hidden md:block">
            <Icon v-if="colorMode.value === 'dark'" 
            name="material-symbols:light-mode" size="24"/>
            <Icon v-if="colorMode.value === 'light'" name="ph:moon" size="20"/>
        </Button>
        
            <slot  name="dropdownmenu" />
        <!-- </nav> -->
</header>

</template >

<script setup lang="ts">
import { useFixedHeader } from 'vue-use-fixed-header';
import {Button} from '@/components/ui/button'
import { useUiStore } from '@/stores/uiStore'
const colorMode = useColorMode()
const { toggleBottomSheet } = useUiStore()

const route = useRoute()

// const headerRef = ref(null);
// const { styles } = useFixedHeader(headerRef);
</script>


