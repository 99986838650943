export const menuItems = [
  {
    item: {
      icon: "streamline:nature-ecology-rice-field-sun-rise-set-field-crop-produce-farm",
      title: "Wellspring",
      href: "/wellspring",
      description: "Scale your expertise and earn",
    },
    subMenuItems: [
      {
        title: "Knowledge Marketplace",
        href: "/planting",
        description: "Scale your expertise and earn",
        icon: "lucide:trees",
      },
    ],
  },
  {
    item: {
      icon: "material-symbols:show-chart-rounded",
      title: "Greenline",
      href: "/greenline",
      description: "Greenline",
    },
    subMenuItems: [
      {
        title: "Better forecasting",
        href: "/forecast",
        description: "Greenlife demand made visible",
        icon: "material-symbols:auto-graph",
      },
    ],
  },
  {
    item: {
      icon: "ant-design:aliwangwang-outlined",
      title: "Design",
      href: "/design",
      description: "",
    },
    subMenuItems: [
      {
        title: "10x faster planting",
        href: "/planting",
        description: "10x faster planting",
        icon: "cil:animal",
      },
    ],
  },
  // {
  //   item: {
  //     icon: "iconoir:reports",
  //     title: "Industry partners",
  //     href: "/industry",
  //     description: "",
  //   },
  //   subMenuItems: [
  //     {
  //       title: "Safeguard biodiversity",
  //       href: "/biodiversity",
  //       description: "Pro-active diversity controls",
  //       icon: "cil:animal",
  //     },

  //     {
  //       title: "Biosecurity",
  //       href: "/biosecurity",
  //       description: "Biosecurity controls",
  //       icon: "cil:animal",
  //     },

  //     {
  //       title: "Supplier assist",
  //       href: "/assistant",
  //       description: "Lighten your inbox with automated triage",
  //       icon: "material-symbols:outgoing-mail-outline-rounded",
  //     },
  //   ],
  // },
];