import { defineStore } from 'pinia'

export const useUserStore = defineStore(
  "UserStore",
  () => {
    const roles = ref(["admin", "basic", "partner"]);
    const role = ref("");
    const teams = ref([]);
    const receiveNewsletter = ref(true);
    const selectedTeam = ref({});
    const subscriptionStatus = ref('');
    const plan = ref(null);
    const user = ref({})

    const setRole = (new_role: string) => {
      role.value = new_role;
    };

    const setTeams = (new_teams: Array<any>) => {
      teams.value = new_teams;
    };

    const setSelectedTeam = (new_team: any) => {
      selectedTeam.value = new_team;
    };

    const setReceiveNewsletter = (status: boolean) => {
      receiveNewsletter.value = status;
    };
      
    const setSubscriptionStatus = (status: string) => {
      subscriptionStatus.value = status;
    };

    const setPlan = (new_plan: object) => {
      plan.value = {...plan.value, ...new_plan}
    };

    const setUser = (new_user: object) => {
      user.value = new_user
    }

    return {
      roles,
      role,
      setRole,
      teams,
      setTeams,
      selectedTeam,
      setSelectedTeam,
      receiveNewsletter,
      setReceiveNewsletter,
      setSubscriptionStatus,
      subscriptionStatus,
      plan,
      setPlan,
      setUser,
      user
    };
  },
  {
    persist: false
  }
);
