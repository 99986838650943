export default defineNuxtPlugin((nuxtApp) => {
  addRouteMiddleware('global-middleware', (to, from) => {
    // if (!to.path.startsWith('/invite-only')) {
    //   // return navigateTo('/invite-only');
    // }
    // Additional logic here
  }, { global: true });
  
});

