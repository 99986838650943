<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/uiStore'

const uiStore = useUiStore()
const { alertConfig } = storeToRefs(uiStore)

const handleAction = (action: 'confirm' | 'cancel', inputText?: string) => {
  uiStore.handleAlertAction(action, inputText)
}
</script>

<template>
  <AlertDialogHeader>
    <AlertDialogTitle class="pointer-events-none text-left" :class="{ 'text-destructive': alertConfig?.destructive }">
      {{ alertConfig?.title }}
    </AlertDialogTitle>
    <AlertDialogDescription class="pointer-events-none">
      {{ alertConfig?.description }}
      <slot name="description-extra"></slot>
      <div v-if="alertConfig?.inputRequired" class="mt-4">
        <Input type="text" v-model="inputText" :placeholder="alertConfig?.inputPlaceholder" class="w-full" />
      </div>
    </AlertDialogDescription>
  </AlertDialogHeader>
  <AlertDialogFooter>
    <Button class="cursor-pointer" v-if="alertConfig?.cancelText!=null" @click="handleAction('cancel')"
      variant="ghost">{{ alertConfig?.cancelText || 'Cancel' }}</Button>
    <Button class="cursor-pointer" :variant="alertConfig?.destructive ? 'destructive' : 'default'"
      :disabled="alertConfig?.inputRequired && !inputText" @click="handleAction('confirm', inputText)">
      {{ alertConfig?.confirmText || 'Confirm' }}
    </Button>
  </AlertDialogFooter>
</template>