<template>

  <Card
    class="text-[0.8rem] mt-4 md:my-8 sm:text-[0.9rem] p-3 h-fit md:h-fit w-[100%] max-w-[400px] flex flex-col pl-[3vw] sm:pl-0 bg-transparent shadow-xl">

    <CardContent class="aspect-square flex flex-col justify-around bg-transparent">

      <p class="text-xl sm:text-2xl md:text-3xl font-semibold tracking-tight py-4 ">
        SuperSeeded {{ plan?.name }}
      </p>
      <ul class="list-none [&>li]:mt-1 md:[&>li]:mt-2 [&>li]:flex [&>li]:items-center [&>li]:gap-2 py-1">
        <li class="" v-for="feature in features" :key="feature">
          <Icon :name="getIconName(feature.icon)" class="inline-block mr-2" />
          <p class="truncate">
            {{ feature.text }}
          </p>

        </li>
      </ul>
      <PlanCardButton v-if="plan" :plan="plan" :disableButton="disableButton" />


    </CardContent>

  </Card>


</template>

<script setup>
import { Icon } from '#components'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)

const props = defineProps({
  features: Array,
  plan: Object,
  disableButton: Boolean
});

const getIconName = (icon) => icon

</script>

<style scoped>
/* Add any specific styles for this component here */
</style>