<script setup lang="ts">
import { useGeolocation, useMousePressed, onClickOutside, onKeyStroke, useFocusWithin } from '@vueuse/core'
import { distance } from '@turf/distance'
import { point } from '@turf/helpers'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
dayjs.extend(relativeTime)
dayjs.extend(calendar)
import { titleCase } from "title-case";

const { coords } = useGeolocation()

const { placeholder } = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
});

const inputValue = ref('')
const openDropDown = ref(false)
const dropdown = ref(null)
const itemSelected = ref(false)
const inputRef = ref(null)
onMounted(() => {
  // Focus the input element when the component is mounted
  inputRef.value?.$el.focus()
})

let { focused: inputFocused } = useFocusWithin(inputRef)
const allowBlur = ref(false)
const { pressed: mousePressed } = useMousePressed()

const emit = defineEmits(['select'])

const { fetchSpeciesDataIndividually } = useSearch()

const dropdownMenuContentRef = ref(null)
const dropdownMenuItemRef = ref([])
const images = ref([])

const names = ref([])

const namesDebounced = ref([])

watch(inputValue, async () => {
  if (inputValue.value && inputValue.value?.trim()?.length > 0) {
    names.value = await fetchSpeciesDataIndividually([inputValue.value], { coords: {latitude: Infinity, longitude: Infinity}, limit: 20, distinct: 'species', sort: [`_geoPoint(${coords.value.latitude}, ${coords.value.longitude}):asc`]})
  } else {
    openDropDown.value = false
  }
})

watch(names, () => {
  if (names.value?.length && !itemSelected.value) {
    openDropDown.value = true;
  } else {
    openDropDown.value = false;
  }
})

const { data: searchImages, error, refresh: imagesRefresh } = await useLazyAsyncData(
  'plantSearchImages',
  () => $fetch('/api/bio/search', {
    method: 'POST',
    body: { searchQuery: namesDebounced.value }
  }),
  {
    watch: [inputValue],
    server: false,
    getCachedData: (key) => {
      return useNuxtApp().payload.data[key] || null;
    }
  }
);

const { data: tnrs, error: tnrsError, refresh: tnrsRefresh } = await useLazyAsyncData(
  'plantTnrs',
  () => $fetch('/api/bio/tnrs', {
    method: 'POST',
    body: { searchQuery: inputValue.value }
  }),
  {
    server: false,
    getCachedData: (key) => {
      return useNuxtApp().payload.data[key] || null;
    }
  }
);

watchDebounced(inputValue, () => {
  tnrsRefresh()
}, { debounce: 500 })


watch(tnrs, () => {
  console.log(tnrs.value)
})

watchDebounced(names, (newNames: any) => {
  // if (newNames && !newNames[0].some((name: any) => name?.images)) {
    namesDebounced.value = newNames[0].map((name: any) => name.species)
    // imagesRefresh()
    // console.log(newNames[0].map(name => name.species))
  // }
}, { debounce: 500 })


onClickOutside(inputValue, (event) => {
  // Commented out for consistency with AddressInput
  // allowBlur.value = true
  inputRef.value?.$el.blur()
  openDropDown.value = false
})

// ... rest of the script setup ...

// Modify handleDropdownSelect to work with Typesense results
const handleDropdownSelect = (item) => {
  emit('select', item)
  openDropDown.value = false
  inputValue.value = item.species
  itemSelected.value = true
  setTimeout(() => {
    itemSelected.value = false
  }, 500)
}


  // onClickOutside(document, () => {
  //   allowBlur.value = true
  // })


watch(inputFocused, (focusState) => {
  if (focusState == false && allowBlur.value == false) {
    if (!mousePressed.value) {
      inputRef.value?.$el.focus()
    }
  }
})

onKeyStroke(['Escape'], (e) => {
  openDropDown.value = false
  allowBlur.value = false
  inputFocused.value = true
  inputRef.value?.$el.focus()
})

onKeyStroke(['Backspace', 'Delete'], (e) => {
  allowBlur.value = false
  inputRef.value?.$el.focus()
  if (import.meta.client) {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Backspace' }))
  }
})

onKeyStroke(['Enter'], (e) => {
  if (!inputFocused.value) {
    openDropDown.value = false
    allowBlur.value = false
  }
})

onKeyStroke(['ArrowUp'], (e) => {
  if (import.meta.client) {
    const activeElement = document.activeElement
    if (activeElement == dropdownMenuItemRef?.value?.[0]?.$el) {
      // inputRef.value?.$el.focus()
    }
  }
})

onKeyStroke(['Tab', 'ArrowDown', 'Enter'], (e) => {
  if (inputFocused.value) {
    dropdownMenuItemRef?.value?.[0]?.$el?.focus()
    if(inputFocused.value==false) {
      allowBlur.value = true 
    }
  } 
})

const computeDistance = (location) => {
  var from = point([coords.value.longitude, coords.value.latitude]);
  var to = point(location)
  var options = { units: "kilometers" };
  return distance(from, to).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' km';
}

const { width: inputWidth } = useElementSize(inputRef)

</script>

<template>
  <div v-auto-animate class="m-0 p-0 flex w-full flex-col justify-start" :class="$attrs.class">
    <!-- TODO: change to Tags-Input (shadcn) -->
    <Input ref="inputRef" v-model="inputValue" :placeholder="placeholder"
      class="text-lg font-semibold tracking-tight text-muted-foreground" />
    <div v-motion-fade-visible v-if="tnrs?.[0]?.Name_matched && inputValue.length"
      class="text-xs text-muted-foreground">
      <VTooltip side="right"
        :content="{__html: `<div class='max-w-[400px]'>Taxonomic Name Resolution Service, Boyle, B. L., Matasci, N., Mozzherin, D., Rees, T., Barbosa, G. C., Kumar Sajja, R., & Enquist, B. J. (2021). Taxonomic Name Resolution Service, version 5.1. In Botanical Information and Ecology Network. https://tnrs.biendata.org/</div>`}">
        <div class="flex flex-row gap-2 mt-2 pl-1 text-[0.9em]">
          <img src="/bien.png" class="w-8 h-auto -mt-[2px] self-center" />
          
          <a target="_blank" class="underline" :href="tnrs?.[0]?.Name_matched_url">{{ tnrs?.[0]?.Name_matched_url }}
            <Icon name="radix-icons:open-in-new-window" class="h-3 w-3 ml-1 -mt-[2px]" />
          </a>
        </div>
      </VTooltip>
    </div>
    <DropdownMenu ref="dropdown" :open="openDropDown">
      <DropdownMenuTrigger class="mt-2 relative">
      </DropdownMenuTrigger>
      <DropdownMenuContent v-motion-fade-visible v-if="names?.[0]?.length" ref="dropdownMenuContentRef" align="start"
        :class="['scale-[1.5] origin-top-left relative border-none pb-3 shadow-xl justify-start self-start items-start -mt-1 mx-0 px-0 dark:bg-background', { 'opacity-0': !names?.length }]">
        <ScrollArea class="h-[25vh] w-full  overflow-visible" :style="{ width: `${inputWidth*1.06}px` }">

          <DropdownMenuItem class="cursor-pointer !hover:bg-muted/20 -mr-3 flex flex-col items-start"
            ref="dropdownMenuItemRef" :style="{ width: inputWidth * 0.998 + 'px'}" v-for="(item, index) in names[0]"
            :key="item.species" @select="handleDropdownSelect(item)">
            <div class="flex flex-row gap-2">
              <div class="min-w-10">
                <VTooltip side="left" :content="`Source: Wikimedia Commons`">
                  <img @load="" v-if="searchImages?.images?.[index]?.url"
                    :src="`https://ik.imagekit.io/8qxqs9cznn/${searchImages?.images?.[index]?.url}`"
                    class="w-12 h-12 rounded-full" />
                </VTooltip>
              </div>
              <div class="flex flex-col justify-between h-full">
                <div class="w-full text-lg">{{ item.species }}</div>
                <div class="text-muted-foreground text-md">{{ item.family }}

                  <VTooltip side="bottom"
                    :content="{ __html: `<span class='font-bold'>Distance (km) to nearest specimen. ${Math.abs(dayjs.unix(item.eventDate).diff(dayjs(), 'year'))} year old (± ${item.coordinateUncertaintyInMeters} meters)</span><br/>Source: GBIF [recorded ${dayjs.unix(item.eventDate).format('MMM D, YYYY')}]`}">

                    <span
                      class="text-muted-foreground text-xs bg-muted-foreground/10 rounded-full px-[6px] py-[3px] font-regular">
                      <Icon name="hugeicons:location-05" class="w-4 h-4 -mt-[3px]" />
                      {{computeDistance(item.location)}}
                    </span>
                  </VTooltip>

                </div>
              </div>
            </div>
          </DropdownMenuItem>
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
    <!-- {{ namesDebounced }} -->
  </div>
</template>

<style scoped>

#plant-input-dropdown {
  width: 100% !important;
}
</style>