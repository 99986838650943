<script setup lang="ts">
const uiStore = useUiStore()
const { sideDrawerOpen, sideSheetDynamicComponent } = storeToRefs(uiStore)


import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DrawerOverlay
} from '@/components/ui/drawer'





</script>

<template>
  <ClientOnly>
    <Drawer direction="left" :modal="false" v-model:open="sideDrawerOpen" :defaultOpen="false">

      <DrawerContent :disableOutsidePointerEvents="false" :trapFocus="false"
        class="h-full min-w-[250px] w-[40vw] rounded-none rounded-r-lg shadow-xxl">
        <component ref="drawer" v-if="sideSheetDynamicComponent" :is="sideSheetDynamicComponent" />
      </DrawerContent>
    </Drawer>
  </ClientOnly>
</template>

<style>
div[role="dialog"] {
  margin: 0;
}

div[data-state="open"][vaul-drawer-visible="true"][vaul-overlay][vaul-snap-points="false"][vaul-snap-points-overlay="true"] {
  opacity: 0.4 !important;
}

div[data-state="open"][vaul-drawer-visible="true"] {
  border: none !important;
}

div[data-dismissable-layer][role="dialog"][data-state="open"][vaul-drawer-visible="true"]>div:first-child {
  display: none !important;
}

</style>