// autoResize.ts
import type { Directive } from 'vue'

export const vAutoResize: Directive = {
  mounted(el: HTMLTextAreaElement) {
    // Preserve existing styles
    const existingStyles = window.getComputedStyle(el)
    const minHeight = existingStyles.minHeight
    const padding = parseFloat(existingStyles.paddingTop) + parseFloat(existingStyles.paddingBottom)
    const extraBottomPadding = 40 // Adjust this value as needed
    // Only hide overflow in the y direction
    el.style.overflowY = 'hidden'
    
    // Don't override resize if it's set
    if (existingStyles.resize === 'none') {
      el.style.resize = 'none'
    }

    const resize = () => {
      el.style.height = minHeight // Reset to min-height
      el.style.height = `${el.scrollHeight - padding + extraBottomPadding}px`;
    }

    el.addEventListener('input', resize)
    window.addEventListener('resize', resize)

    // Initial resize
    resize()

    // Clean up
    el._cleanup = () => {
      el.removeEventListener('input', resize)
      window.removeEventListener('resize', resize)
    }
  },
  unmounted(el: HTMLTextAreaElement) {
    if (el._cleanup) el._cleanup()
  }
}