import revive_payload_client_AzqxKIgg3c from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Cy2hHaJZlv from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_K0zWJzmUaN from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SVpL22o8xa from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.14_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.3_@types+node@20.14.11_sa_gg2wclviad5me7vkaal3ruzmva/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_xN1bvT0pFK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_NjDJaisP3t from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Q4UTNE7kyo from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kFRSRDU5p7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KsrqUHzyx9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KdfLHVP7Hs from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.6.2_vue@3.4.31_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5iPrsxv8Cd from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_rgfycioimnc325324vohaew3ty/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JQfFSnLhaj from "/vercel/path0/node_modules/.pnpm/nuxt-echarts@0.2.2_echarts@5.5.1_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_7vveClhMA7 from "/vercel/path0/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.4.31_typescript@5.6.2_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_3mnPdDONi5 from "/vercel/path0/node_modules/.pnpm/nuxt-split-type@0.1.8_magicast@0.3.4_nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_e_y5ubk6zyct4rmkoxs3mve6vnua/node_modules/nuxt-split-type/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/vercel/path0/.nuxt/gsapPlugin.mjs";
import plugin_JcUGkGY7y3 from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.9_magicast@0.3.4_rollup@4.18.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_HWGeuifRpB from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import anchorscroll_plugin_zq9Jl90Wu3 from "/vercel/path0/node_modules/.pnpm/nuxt-anchorscroll@1.0.3_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import _001_notivue_client_bE2FHKqHiu from "/vercel/path0/.nuxt/001.notivue.client.mjs";
import motion_AgCPrQxKEd from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.3_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.6.2_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_client_ZJfltoGOVd from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import auth_redirect_37V93RytoR from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_ymxeWlctGN from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typesc_kcd3ezoifgkn6oybjxanhetreq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _000_gsap_client_FA29JWF3Bf from "/vercel/path0/plugins/000.gsap.client.ts";
import _010_sentry_client_qtk3FqHvvX from "/vercel/path0/plugins/010.sentry.client.ts";
import _011_vue_code_layout_client_YsRSp6uV1S from "/vercel/path0/plugins/011.vue-code-layout.client.ts";
import _012_vue_photo_preview_client_xt8Txlkm6Q from "/vercel/path0/plugins/012.vue-photo-preview.client.ts";
import _020_fit2box_client_7dQ8xfNgoy from "/vercel/path0/plugins/020.fit2box.client.ts";
import _030_uplot_client_sOHiDendNY from "/vercel/path0/plugins/030.uplot.client.ts";
import _040_auto_animate_client_kSN6cGeXRu from "/vercel/path0/plugins/040.auto-animate.client.ts";
import _070_uppy_client_yepT13vxoE from "/vercel/path0/plugins/070.uppy.client.ts";
import _080_error_handler_pzY4GmDTGp from "/vercel/path0/plugins/080.error-handler.ts";
import _091_vgrid_client_lex8Ugz66y from "/vercel/path0/plugins/091.vgrid.client.ts";
import _092_dom_to_image_more_client_WTdQBsQ2DJ from "/vercel/path0/plugins/092.dom-to-image-more.client.ts";
import _093_vue_pdf_embed_client_rLrumfOI8S from "/vercel/path0/plugins/093.vue-pdf-embed.client.ts";
import authNavGuard_client_5VQHzXtSXe from "/vercel/path0/plugins/authNavGuard.client.ts";
export default [
  revive_payload_client_AzqxKIgg3c,
  unhead_Cy2hHaJZlv,
  router_K0zWJzmUaN,
  _0_siteConfig_SVpL22o8xa,
  supabase_client_xN1bvT0pFK,
  payload_client_NjDJaisP3t,
  navigation_repaint_client_Q4UTNE7kyo,
  check_outdated_build_client_kFRSRDU5p7,
  chunk_reload_client_KsrqUHzyx9,
  plugin_vue3_KdfLHVP7Hs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5iPrsxv8Cd,
  plugin_JQfFSnLhaj,
  plugin_7vveClhMA7,
  plugin_3mnPdDONi5,
  gsapPlugin_HlwIIyl85L,
  plugin_JcUGkGY7y3,
  plugin_HWGeuifRpB,
  anchorscroll_plugin_zq9Jl90Wu3,
  _001_notivue_client_bE2FHKqHiu,
  motion_AgCPrQxKEd,
  plugin_client_ZJfltoGOVd,
  auth_redirect_37V93RytoR,
  plugin_ymxeWlctGN,
  _000_gsap_client_FA29JWF3Bf,
  _010_sentry_client_qtk3FqHvvX,
  _011_vue_code_layout_client_YsRSp6uV1S,
  _012_vue_photo_preview_client_xt8Txlkm6Q,
  _020_fit2box_client_7dQ8xfNgoy,
  _030_uplot_client_sOHiDendNY,
  _040_auto_animate_client_kSN6cGeXRu,
  _070_uppy_client_yepT13vxoE,
  _080_error_handler_pzY4GmDTGp,
  _091_vgrid_client_lex8Ugz66y,
  _092_dom_to_image_more_client_WTdQBsQ2DJ,
  _093_vue_pdf_embed_client_rLrumfOI8S,
  authNavGuard_client_5VQHzXtSXe
]