import { useGeolocation } from '@vueuse/core'

export function useSearch() {
  const { search } = useMeiliSearch('gbif')
  const meilisearch = useMeiliSearchRef()

  const results: Ref<any[]> = ref([])
  const status = ref('idle')

  const fetchSpeciesDataIndividually = async (data: string[], opts: {
    coords?: { latitude: number, longitude: number },
    limit?: number,
    distinct?: string,
    sort?: string[]
  } = {}) => {
    const { coords = { latitude: Infinity, longitude: Infinity }, limit = 1, distinct = 'species', sort = [] } = opts;
    
    status.value = 'pending'
    results.value = []
      for (let d of data) {
        
      const { data: speciesData } = await useAsyncData(
        `botanical-names-${d}`,
        async () => {
          const searchOptions: any = { limit, distinct }
          if (coords.latitude != Infinity && coords.longitude != Infinity) {
            searchOptions.sort = [`_geoPoint(${coords.latitude}, ${coords.longitude}):asc`]
          }
          return await search(d, searchOptions)
        },
        {
          getCachedData: (key) => {
                const nuxtApp = useNuxtApp()
                // console.log('using cache?', nuxtApp.payload.data[key] || nuxtApp.static.data[key] ? 'yes' : 'no')
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
          },
        }
      )
      results.value.push(speciesData.value?.hits || [])
    }
    
    status.value = 'idle'
    return results.value
  }

  const fetchSpeciesDataFederated = async (data: string[], coords: any = {latitude: Infinity, longitude: Infinity}) => {
    status.value = 'pending'
    const { data: speciesData } = await useAsyncData(
      `botanical-names-${data.join('-')}`,
      async () => {
        const searchOptions = {} as any
        if (coords.latitude != Infinity && coords.longitude != Infinity) {
          searchOptions.sort = [`_geoPoint(${coords.latitude}, ${coords.longitude}):asc`]
        }
        return await meilisearch.multiSearch({ federation: { limit: 1 }, queries: data.map(d => ({ indexUid: 'gbif', q: d, ...searchOptions })) })
      },
      {
        getCachedData: (key) => {
          const nuxtApp = useNuxtApp()
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
      }
    )
    results.value = speciesData.value?.hits || []

    status.value = 'idle'
    return results.value
  }

  return {
    results,
    status,
    fetchSpeciesDataIndividually,
    fetchSpeciesDataFederated
  }
}